.App-logo {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  max-width: 100%;
  width: 160px;
  height: auto;
}

.App-header {
  background-color: #fff;
  padding: 10px;
  text-align: center;
}

.App-content {
  max-width: 1180px;
  margin: 0 auto;
  padding: 8px;
}

.App-Footer {
  max-width: 1180px;
  margin: 0 auto;
  color: #555;
  font-size: 12px;
  padding: 16px;
  text-align: center;
}

.App-Footer a {
  color: rgb(66, 69, 253);
}

.App-intro {
  font-size: large;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

._appLinks_e6ad3 {
  display: block;
  margin: 1em;
  padding: 0;
  list-style: none;
}

._appLinks_e6ad3 > li {
  display: block;
  white-space: nowrap;
}

._appLinks_e6ad3 > li + li {
  margin-top: 8px;
}

.aprTable {
  width: 360px;
  margin: 0 auto;
}

.bFold {
  margin-top: 200px;
  border-top: 1px solid #ddd;
  width: 100%;
}

@media only screen and (min-width: 960px) {
  ._appLinks_e6ad3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  ._appLinks_e6ad3 > li + li {
    margin-top: 0;
    margin-left: 8px;
  }
  ._appLinks_e6ad3 > li:after {
    content: "\2022";
    margin-left: 8px;
  }
  ._appLinks_e6ad3 > li:last-child:after {
    content: "";
    margin: 0;
  }
}
